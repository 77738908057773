import React from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import RichText from '~components/RichText'
import Section from '~components/Section'
import Image from '~components/Image'

const AboutContentSection = ({ className, image, title, subtitle, text, alignment, id }) => {

  const {mobile} = breakpoints

  return (
    <Section
      className={className}
      id={id}
      css={css`
        grid-template-rows: max-content 1fr;
      `}
    >
      <Image
        asset={image}
        aspectRatio={0.704}
        css={css`
          grid-column: ${alignment === `right` ? `7/12` : ` 2/-7`};
          grid-row: span 2;
          max-width: calc(100vh / 1.6);
          ${mobile}{
            grid-row: 2;
            grid-column: 6/13;
            margin-bottom: 38px;
          }
      `}/>
      <div 
        css={css`
          grid-column: ${alignment === `right` ? `2/6` : ` 8/12`};
          grid-row: 1;
          ${mobile}{
            grid-row: 1;
            grid-column: span 12;
            margin-bottom: 65px;
          }
      `}>
        <h4 
          css={css`
            margin-bottom: 34px;
            ${mobile}{
              margin-bottom: 28px;
            }
          `}>
            {title}
        </h4>
        <p dangerouslySetInnerHTML={{
          __html: subtitle
        }}/>
      </div>
      <div css={css`
        grid-column: ${alignment === `right` ? `2/6` : ` 8/12`};
        grid-row: 2;
        align-self: end;
        margin-bottom: 10px;
        ${mobile}{
          grid-row: 3;
          grid-column: span 12;
        }
      `}>
        <RichText content={text}/>
      </div>
    </Section>
  )
}

AboutContentSection.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string, 
  text: PropTypes.array, 
  alignment: PropTypes.string,
}

AboutContentSection.defaultProps = {
  title: `Our Philosophy`,
  subtitle: `We are driven by our desire for elegant, versatile, contemporary furniture that is made for modern living.`,
  text: [
    {
        "_key": "f9d17a10baa5",
        "_type": "block",
        "children": [
            {
                "_key": "8f25454437bd",
                "_type": "span",
                "marks": [],
                "text": "Sed a libero a ex viverra ultrices at vitae neque. Nulla id neque in mi semper interdum sed nec neque. Nunc placerat ac magna quis hendrerit. Proin accumsan porta quam a mollis. Vestibulum massa nunc, porttitor vitae sapien nec, vehicula pretium arcu. Nunc diam leo, euismod quis eros non, placerat tincidunt lorem. Nulla mollis turpis lacus, et pulvinar neque finibus in. Curabitur blandit mollis dictum. Aliquam ut dolor eu ex auctor consequat non in mi. Integer velit sapien, sodales id ante at, egestas pulvinar tellus. Pellentesque a neque velit."
            }
        ],
        "markDefs": [],
        "style": "normal"
    },
    {
        "_key": "8b2a5b979d61",
        "_type": "block",
        "children": [
            {
                "_key": "b8c2c46be150",
                "_type": "span",
                "marks": [],
                "text": "Nunc diam leo, euismod quis eros non, placerat tincidunt lorem. Nulla mollis turpis lacus, et pulvinar neque finibus in. Curabitur blandit mollis dictum. Aliquam ut dolor eu ex auctor consequat non in mi. Integer velit sapien, sodales id ante at, egestas pulvinar tellus. Pellentesque a neque velit."
            }
        ],
        "markDefs": [],
        "style": "normal"
    },
    {
        "_key": "02f29e9a6d0e",
        "_type": "block",
        "children": [
            {
                "_key": "bf92314dfe64",
                "_type": "span",
                "marks": [],
                "text": ""
            }
        ],
        "markDefs": [],
        "style": "normal"
    }
  ], 
  alignment: `right`,
}

export default AboutContentSection
