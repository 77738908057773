import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import { Plus } from '../../Svg';

const AccordionItem = ({ className, title, children }) => {

  const {mobile} = breakpoints

  const [open, setOpen] = useState(false)

  return (
    <div css={css`
      border-bottom: 1px solid var(--grey); 
    `}>
      <button
        onClick={() => setOpen(!open)}
        className={className}
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
					text-align: left;
          padding: 25px 0;
          width: 100%;
          ${mobile}{
            padding: 22px 0;
          }
        `}
      >
        <h4>{title}</h4>
        <Plus css={css`
          width: 15.5px;
          height: 15.5px;
          margin-right: 4px;
          transform: ${open ? `rotate(45deg)` : `rotate(0deg)` };
          transition: 0.3s ease-in-out transform;
          ${mobile}{
            width: 13px;
            height: 13px;
            margin-right: 0px
          }
        `}/>
      </button>
      <SlideDown 
        className={'my-dropdown-slidedown'} 
        css={css`
          transition: 0.4s ease-in-out;
      `}>
        {open ? 
        <div css={css`
          padding-top: 5px;
          padding-bottom: 25px;
          ${mobile}{
            padding-top: 10px;
            padding-bottom: 30px;
          }
        `}
        >
          {children}
        </div>
        : null
        }
      </SlideDown>
    </div>
  )
}

AccordionItem.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
}

AccordionItem.defaultProps = {
  title: 'title',
  children: 'children test',
}

export default AccordionItem
