import React from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import RichText from '~components/RichText'
import Section from '~components/Section'
import Image from '~components/Image'

const AboutCenteredContentSection = ({ className, image, title, subtitle, text, id }) => {
  
  const {mobile} = breakpoints

  return (
    <Section
      className={className}
      id={id}
    >
      <div css={css`
        grid-column: 3/11;
        text-align: center;
        ${mobile}{
          grid-column: span 12;
        }
      `}>
        <Image
          asset={image}
          css={css`
            margin-bottom: 68px;
            ${mobile}{
              margin-bottom: 58px;
            }
        `}/>
        <h2 css={css`
          margin-bottom: 40px;
          ${mobile}{
            margin-bottom: 22px;
          }
        `}>{title}</h2>
        <p css={css`
          padding-bottom: 40px;
          ${mobile}{
            padding-bottom: 34px;
          }
        `}>{subtitle}</p>
        <div css={css`
          max-width: 552px;
          margin: 0 auto;
          padding: 0 20px;
        `}>
          <RichText content={text}/>
        </div>
      </div>
    </Section>
  )
}

AboutCenteredContentSection.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string, 
  text: PropTypes.array, 
}

AboutCenteredContentSection.defaultProps = {
  title: `Our Range`,
  subtitle: `Confidence in Customisation`,
  text: [
    {
        "_key": "f9d17a10baa5",
        "_type": "block",
        "children": [
            {
                "_key": "8f25454437bd",
                "_type": "span",
                "marks": [],
                "text": "Sed a libero a ex viverra ultrices at vitae neque. Nulla id neque in mi semper interdum sed nec neque. Nunc placerat ac magna quis hendrerit. Proin accumsan porta quam a mollis. Vestibulum massa nunc, porttitor vitae sapien nec, vehicula pretium arcu. Nunc diam leo, euismod quis eros non, placerat tincidunt lorem. Nulla mollis turpis lacus, et pulvinar neque finibus in. Curabitur blandit mollis dictum. Aliquam ut dolor eu ex auctor consequat non in mi. Integer velit sapien, sodales id ante at, egestas pulvinar tellus. Pellentesque a neque velit."
            }
        ],
        "markDefs": [],
        "style": "normal"
    },
    {
        "_key": "8b2a5b979d61",
        "_type": "block",
        "children": [
            {
                "_key": "b8c2c46be150",
                "_type": "span",
                "marks": [],
                "text": "Nunc diam leo, euismod quis eros non, placerat tincidunt lorem. Nulla mollis turpis lacus, et pulvinar neque finibus in. Curabitur blandit mollis dictum. Aliquam ut dolor eu ex auctor consequat non in mi. Integer velit sapien, sodales id ante at, egestas pulvinar tellus. Pellentesque a neque velit."
            }
        ],
        "markDefs": [],
        "style": "normal"
    },
    {
        "_key": "02f29e9a6d0e",
        "_type": "block",
        "children": [
            {
                "_key": "bf92314dfe64",
                "_type": "span",
                "marks": [],
                "text": ""
            }
        ],
        "markDefs": [],
        "style": "normal"
    }
  ], 
}

export default AboutCenteredContentSection
