import React, { useEffect, useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import Section from '~components/Section'
import RichText from '~components/RichText'
import BrandCard from '~components/blocks/BrandCard'
import Button from '~components/blocks/Button'
import resolveLink from '~utils/resolveLink'

const BrandsListSection = ({ className, brands, title, text, allBrandsLink, id }) => {

  const {mobile} = breakpoints

  const [selectedBrand, setSelectedBrand] = useState(null)
  const [cardHidden, setCardHidden] = useState(true)
  const [windowWidth, setWindowWidth] = useState(0)

  useLayoutEffect(() => {
    setWindowWidth(window.innerWidth)
    window.addEventListener('resize', e => {
      setWindowWidth(window.innerWidth)
    })
    return window.removeEventListener('resize', e => {
      setWindowWidth(window.innerWidth)
    })
  }, [])

  const changeCard = newBrand => {
    setCardHidden(true)
    if(!selectedBrand) {
      setSelectedBrand(newBrand)
      setCardHidden(false)
    } else {
      window.setTimeout(() => {
        setSelectedBrand(newBrand)
        setCardHidden(false)
      }, 500)
    }
  }

  return (
    <Section
      className={className}
      id={id}
    >
      <div css={css`
        grid-column: 3/6;
        ${mobile}{
          grid-column: span 12;
          margin-bottom: 58px;
        }
      `}>
        <h4 css={css`
          margin-bottom: 25px;
          ${mobile}{
            margin-bottom: 26px;
          }
        `}>
          {title}
        </h4>
        <div css={css`
            margin-bottom: 38px;
            ${mobile}{
              margin-bottom: 36px;
            }
          `}>
            <RichText
              content={text}
            />
        </div>
        <Button to={allBrandsLink}>More about our brands</Button>
      </div>
      <div css={css`
        grid-column: 7/9;
        ${mobile}{
          grid-column: 1/6;
          grid-row: 2;
        }
      `}>
        {brands?.map((brand, index) =>(
          <button
            key={brand?.node?.id}
            onClick={windowWidth <= 1024 ? () => changeCard(brand) : null}
            onMouseEnter={windowWidth >= 1025 ? () => changeCard(brand) : null}
            className="h4"
            css={css`
              color:${ selectedBrand === null ? `inherit` : selectedBrand === brand ? `inherit` : `var(--grey)`};
              transition: 0.2s color;
              margin-bottom: 24px;
              display: block;
              text-align: left;
              ${mobile}{
                margin-bottom: 20px;
              }
          `}
          >
            {brand?.node?.title}
          </button>
        ))}
      </div>
      <div css={css`
        grid-column: 9/13;
        ${mobile}{
          grid-column: 7/13;
          grid-row: 2;
        }
      `}>
        <BrandCard
          brandLink={resolveLink(selectedBrand?.node?._type, selectedBrand?.node?.title)}
          catalogueLink={resolveLink('brandFilter', selectedBrand?.node?.title)}
          image={selectedBrand?.node?._rawContent?.image?.asset?.id}
					aspectRatio={1.5}
          css={css`
            opacity: ${cardHidden ? `0` : `1`};
            transform: ${cardHidden ? `translateY(30px)` : `translateY(0)`};
            transition: opacity 0.3s, transform 0.5s;
          `}
        />
      </div>
    </Section>
  )
}

BrandsListSection.propTypes = {
  brands: PropTypes.array,
  text: PropTypes.array,
  allBrandsLink: PropTypes.string,
}

BrandsListSection.defaultProps = {
  title: `Our Brands`,
  allBrandsLink: `/`,
  brands: [
    {
      node: {
        _type: "brand",
        key:"Capdell",
        title:"Capdell",
        slug: {
          current: "/",
        }
      }
    },
    {
      node: {
        _type: "brand",
        key:"Cattelan Italia",
        title:"Cattelan Italia",
        slug: {
          current: "/",
        }
      }
    },
  ],
  text: [
    {
        "_key": "f9d17a10baa5",
        "_type": "block",
        "children": [
            {
                "_key": "8f25454437bd",
                "_type": "span",
                "marks": [],
                "text": "Sed a libero a ex viverra ultrices at vitae neque. Nulla id neque in mi semper interdum sed nec neque. Nunc placerat ac magna quis hendrerit. Aliquam ut dolor eu ex auctor consequat non in mi. Integer velit sapien, sodales id ante at, egestas pulvinar tellus. Pellentesque a neque velit."
            }
        ],
        "markDefs": [],
        "style": "normal"
    },
    {
        "_key": "8b2a5b979d61",
        "_type": "block",
        "children": [
            {
                "_key": "b8c2c46be150",
                "_type": "span",
                "marks": [],
                "text": "Nunc diam leo, euismod quis eros non, placerat tincidunt lorem. Nulla mollis turpis lacus, et pulvinar neque finibus in. Curabitur blandit mollis dictum. Pellentesque a neque velit."
            }
        ],
        "markDefs": [],
        "style": "normal"
    },
    {
        "_key": "02f29e9a6d0e",
        "_type": "block",
        "children": [
            {
                "_key": "bf92314dfe64",
                "_type": "span",
                "marks": [],
                "text": ""
            }
        ],
        "markDefs": [],
        "style": "normal"
    }
  ],
}

export default BrandsListSection
