import React from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import { Link } from "gatsby"
import Image from '~components/Image'
import Button from '~components/blocks/Button'

const BrandCard = ({ className, brandLink, catalogueLink, image, aspectRatio }) => {

  const {mobile} = breakpoints
  return (
    <div
      className={className}
    >
        {brandLink &&
          <Link 
            to={brandLink}
            css={css`
              margin-bottom: 28px;
              display: block;
          `}>
            <Image
              asset={image}
              aspectRatio={aspectRatio ? aspectRatio : 0.731}
            />
          </Link>
        }
        <Button 
          to={brandLink} 
          css={css`
            margin-bottom: 24px;
            display: block;
            ${mobile} {
              margin-bottom: 22px;
            }
        `}>More about the brand</Button>
        <Button to={catalogueLink}>See Products</Button>
    </div>
  )
}

BrandCard.propTypes = {
  image: PropTypes.string,
  brandLink: PropTypes.string,
  catalogueLink: PropTypes.string,
  className: PropTypes.string,
}

BrandCard.defaultProps = {
  brandLink: `/`,
  catalogueLink: `/`,
}

export default BrandCard
